import React, {useState, useMemo, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";
import { CancelToken, isCancel } from "axios"; //Cliente HTTP

import {
    Button,
    Card,
    CardBody, CardFooter,
    CardTitle,
    Col,
    Container,
    Form, FormFeedback,
    Input,
    Label, Modal, ModalBody, ModalFooter, ModalHeader, NavItem, NavLink,
    Row, TabContent, TabPane
} from "reactstrap";
import Switch from "react-switch";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './style.css'
import { typesProduct } from "common/data";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useFormik} from "formik";
import * as Yup from "yup";

import {
    saveProduct as onSaveProdocut,
    getProductDetail as onGetProductDetail,
    setProductClean as onSetProductClean,
    setProductVariantFlag as onSetProductVariantFlag,
    addRowVariantProduct as onAddRowVariantProduct,
    removeRowVariantProduct as onRemoveRowVariantProduct,
    
    productOptionCheck as onProductOptionCheck,
    changeVariantValue as onChangeVariantValue,
    changeVariantValueAll as onChangeVariantValueAll,
    handleSetPriceVariants as onHandleSetPriceVariants,
    handleSetVariantEditable as onHandleSetVariantEditble,
    productPublishShopify as onProductPublishShopify,
} from "store/product/actions";
import { acceptedTermsCraftsmanSave as onAcceptedTermsCraftsmanSave } from "store/auth/register/actions"
import { getProfile as onGetProfile } from "store/auth/profile/actions"

import {useSelector, useDispatch } from "react-redux";
import classnames from "classnames"
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axiosApi, {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {SHOW_PRELOADER} from "../../store/layout/actionTypes";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import {getCroppedImg, getCroppedImgBlobAlt, getRotatedImage} from "../../helpers/canvasUtils";
import imageCompression from "browser-image-compression";
import ProductVariantImages from "./ProductVariantImages";
import Index from "pages/ImageGallery";
import InputVariantValues from "./InputVariantValues/InputVariantValues";
import ImageGallery from "pages/ImageGallery/ImageGallery";
import RenderByCond from "components/Common/RenderByCond";
import TermsArtisan from "../../components/Common/TermsArtisan/TermsArtisan";


const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    );
};

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            SI
        </div>
    );
};


const ProductEdit = props => {

    const dispatch = useDispatch();

    const { imageSelected } = useSelector(state => ({
        imageSelected: state.ImageGallery.imageSelected
      })); 
      
    const [croppedImage, setCroppedImage] = useState(null);

    const { product, productVariant, sizeList, colorList, styleList, addSizeCheck,addColorCheck, addStyleCheck,
    variantsBuild,options, variantEditable} = useSelector(state => ({
        product: state.Products.product,
        productVariant: state.Products.productVariant,
        sizeList: state.Products.sizeList,
        colorList: state.Products.colorList,
        styleList: state.Products.styleList,
        addSizeCheck: state.Products.addSizeCheck,
        addColorCheck: state.Products.addColorCheck,
        addStyleCheck: state.Products.addStyleCheck,
        variantsBuild: state.Products.variantsBuild,
        options: state.Products.options,
        variantEditable: state.Products.variantEditable
    }));

    const { profile } = useSelector(state => ({
        profile: state.Profile.profile,
    }));

    //meta title
    document.title = `${props?.type=='New'?"Nuevo":"Editando"} producto | Guelaguetza Designs`;

    const  { productSaveSuccess } = useSelector(state => ({
        productSaveSuccess: state.Products.productSaveSuccess,
    }));

    const  { productIDSaved } = useSelector(state => ({
        productIDSaved: state.Products.productIDSaved,
    }));

    const  { activeTab } = useSelector(state => ({
        activeTab: state.Products.activeTab,
    }));

    const params = props.router.params;

    const [acceptTermsShow, setAcceptTermsShow] = useState(false)

    const [selectedFiles, setSelectedFiles] = useState([])
    const [priceMain, setPriceMain] = useState("")

    const[prices,setPrices] = useState([]);
    const[quantityList,setQuantityList] = useState([]);

    const [typeSubmit, setTypeSubmit] = useState({
        title : "Nuevo Producto",
        type : "new"
    });


    const [images, setImages] = useState([]);
    const [productImage, setProductImage] = useState(false);
    const [imageGalleryShow,setImageGalleryShow]=useState(false)
    const toggleImageGalleryModal = (variant,key) => {
        const imageGalleryShowL = !imageGalleryShow
        if(variant && key>=0 && imageGalleryShowL){
            console.info(variant)
            console.info(key)
            dispatch(onHandleSetVariantEditble({item:variant,key:key}))
        } 
        if(!imageGalleryShowL) {
            dispatch(onHandleSetVariantEditble(null))
            setProductImage(false)
        }
        setImageGalleryShow(imageGalleryShowL)
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (product && product.name) || '',
            titleShopify: (product && product.shopify_title) || '',
            weight: (product && product.weight) || "",
            weightIn: (product && product.weight_in) || "",
            width: (product && product.width) || "",
            length: (product && product.length) || "",
            depth: (product && product.depth) || "",
            capacity: (product && product.capacity) || "",
            productVariantInput: product && product.with_variant===1,
            description: (product && product.body) || '',
            bodyHtml: (product && product.body_html) || '',
            priceUnit: (product && product.price) || '',
            quantity: (product && product.quantity) || '',
            typeProduct: (product && product.product_type) || '',
            variantList:{}
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor ingresa el nombre del producto"),
            titleShopify:Yup.string().when([], {
                is: () => profile?.role===1,
                then: Yup.string().required("Ingresa el nombre para shopify"),
                otherwise: Yup.string().notRequired(),
            }),
            bodyHtml:Yup.string().when([], {
                is: () => profile?.role===1,
                then: Yup.string().required("Ingresa la descripción para shopify"),
                otherwise: Yup.string().notRequired(),
            }),
            typeProduct:Yup.string().when([], {
                is: () => profile?.role===1,
                then: Yup.string().required("Elige el tipo para shopify"),
                otherwise: Yup.string().notRequired(),
            }),
            weight: Yup.string().required("Por favor ingresa el peso del producto"),
            weightIn: Yup.string().required("Por favor selecciona Kg o Gramos"),
            length: Yup.string().required("Por favor ingresa el largo del producto"),
            width: Yup.string().required("Por favor ingresa el ancho del producto"),
            description: Yup.string().required("Por favor ingresa la descripción del producto"),
            productVariantInput: Yup.boolean("Indica si tu producto tiene variantes"),
            priceUnit: Yup.number().when("productVariantInput",{
                is:false,
                then:Yup.number().required("Por favor ingresa el precio en pesos mexicanos")
            }),
            quantity: Yup.number().when(["productVariantInput"],{
                is:false,
                then:Yup.number().required("Por favor ingresa la cantidad disponible ")
            }),
        }),
        onSubmit: (values) => {

                const newProduct = {
                    name: values["name"],
                    titleShopify: values["titleShopify"],
                    description: values["description"],
                    bodyHtml: values["bodyHtml"],
                    typeProduct: values["typeProduct"],
                    weightIn: values["weightIn"],
                    weight: values["weight"],
                    length: values["length"],
                    width: values["width"],
                    depth: values["depth"],
                    capacity: values["capacity"],
                    priceUnit: values["priceUnit"],
                    quantity: values["quantity"],
                    productVariant: productVariant,
                    variants:variantsBuild,
                    options:options,
                    selectedFiles:selectedFiles,
                    quantityList:quantityList,
                    prices:prices,
                    id:productIDSaved ? productIDSaved:null,
                    images:images
                };
                if(productStatus && !productEditable) {
                    dispatch(onProductPublishShopify({
                        id:productIDSaved,
                        status:productStatus
                    }));
                    
                } else {
                    dispatch(onSaveProdocut(newProduct));
                    handlePruductEditable();
                }
        },
    });

    const [productStatus, setProductStatus] = useState(null);
    const [productEditable, setProductEditable] = useState(true);
    const handlePruductEditable = () => {
        const pEdit = !productEditable
        setProductEditable(pEdit)
    }
    

    function handleProductVariant  ()  {
        let variantFlag = !productVariant
        validation.setFieldValue("productVariantInput",variantFlag)
        dispatch(onSetProductVariantFlag(variantFlag))
    }

    function handleChangeVariantValue(variant,e, key, rooteable=false, currentValue='',serverId=null) {
        e.preventDefault();
        
        //if(rooteable){
        //    dispatch(onChangeVariantValue({name:variant,key:key,value:e.target.value,rooteable:rooteable, isInitialCreation: true }))
        //} else {
            dispatch(onChangeVariantValue({name:variant,key:key,value:e.target.value,rooteable:rooteable, isInitialCreation: productIDSaved,currentValue:currentValue,serverId:serverId }))
        //}
    }

    function handleAddRowVariantProduct(variant) {
        dispatch(onChangeVariantValueAll())
        dispatch(onAddRowVariantProduct({name:variant.name,val:"",key:null}))
    }

    const addPriceVariant = (key,item,val,target,iter_int=null) => {
        dispatch(onHandleSetPriceVariants({key:key,item:item,value:val,target:target,iter_int:iter_int}))
    }

    const handleAddPriceVariant = (key,item,value,e=null,target,iter_int=null) =>  {
        if(e!=null){
            e.preventDefault();
        }
        addPriceVariant(key,item,value,target,iter_int)
    }

    const handleApplyPriceAll = () => {
        let target = product?.rooteable ? "price_admin":"price";
    
        variantsBuild?.map((item, key)=> {
            addPriceVariant(key,item,priceMain,target)
        })
    }

    const handleResetPriceAll = () => {
        let target = product?.rooteable ? "price_admin":"price";
        variantsBuild?.map((item, key)=> {
            addPriceVariant(key,item,"",target)
        })
        setPriceMain("")
    }

    useEffect(() => {

        dispatch(onSetProductClean({}));
        if (params.uuid) {
            dispatch(onGetProductDetail(params.uuid))
        }
    }, [dispatch]);

    useEffect(()=> {
        setImages((product && product?.images)?product.images:[])
    },[product]);

    useEffect(() => {
        if (props.type && product?.id) {
            dispatch(onSetProductClean({}));
        }
    }, [props]);

    useEffect(() => {
        if(!profile?.email) {
            dispatch(onGetProfile(null))
        }

        if(profile?.terms_accepted || profile?.role===1) {
            setAcceptTermsShow(profile?.terms_accepted || profile?.role===1)
        }
    }, [profile]);

    const handleAcceptedTerms = () => {
        const profileCopy = {
            acceptedTerms : true
        }
        dispatch(onAcceptedTermsCraftsmanSave(profileCopy))
    }

    const handleSetProductBodyHtml = (data) => {
        
        validation.setFieldValue("bodyHtml", data);
    }

    useEffect(() => {
        if(variantEditable && imageSelected){
            handleAddPriceVariant(variantEditable.key,variantEditable.item,imageSelected,null,"image")
        } 
        if(productImage && imageSelected) {
            const imagesL = images.length>0?[...images]:[]
            imagesL.push(imageSelected)
            setImages(imagesL)
        }
    }, [imageSelected])

    const handleRemoveImagesToProduct = key => {
        const imagesL = [...images]
        imagesL.splice(key,1)
        setImages(imagesL)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Producto" breadcrumbItem={props?.type=='New'?"Nuevo Producto":"Editando Producto"} />
                    
                    <Card>
                        <CardBody>
                        <Row>
                            <Col xs="12">
                                <Card>
                                            <CardBody>
                                                <CardTitle>Información básica</CardTitle>
                                                <p className="card-title-desc mb-4">
                                                    Completa la siguiente información (los campos con * son obligatorios)
                                                </p>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}>
                                                    <Row>
                                                        <Col sm="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="productname">Nombre del producto *</Label>
                                                                <Input
                                                                    name="name" type="text" className={"form-control"}
                                                                    placeholder="Ingresa el nombre del producto"
                                                                    onChange={validation.handleChange} disabled={product?.rooteable || !productEditable}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.name || ""}
                                                                    invalid={
                                                                        validation.touched.name && validation.errors.name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.name && validation.errors.name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                            <RenderByCond cond={product?product?.rooteable:false} child={
                                                            <div className="mb-3">
                                                                <Label htmlFor="productname">Nombre del producto para shopify *</Label>
                                                                <Input
                                                                    name="titleShopify" type="text" className={"form-control"}
                                                                    placeholder="Ingresa el nombre " disabled={!productEditable}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.titleShopify || ""}
                                                                    invalid={
                                                                        validation.touched.titleShopify && validation.errors.titleShopify ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.titleShopify && validation.errors.titleShopify ? (
                                                                    <FormFeedback type="invalid">{validation.errors.titleShopify}</FormFeedback>
                                                                ) : null}
                                                            </div>}/>

                                                            <Row>
                                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                                    <div className="mb-3">
                                                                    <Label htmlFor="manufacturername">
                                                                        Peso para envío *
                                                                    </Label>
                                                                    <Input
                                                                        id="weight"
                                                                        name="weight"
                                                                        type="number" disabled={product?.rooteable || !productEditable}
                                                                        className="form-control"
                                                                        placeholder="Ingresa el peso "
                                                                        onChange={validation.handleChange} 
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.weight || ""}
                                                                        invalid={
                                                                            validation.touched.weight && validation.errors.weight ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.weight && validation.errors.weight ? (
                                                                        <FormFeedback type="invalid">{validation.errors.weight}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                                </Col>
                                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="weightIn">
                                                                            Peso En *
                                                                        </Label>
                                                                        <select id="weightIn" name={"weightIn"}
                                                                                onChange={validation.handleChange} disabled={product?.rooteable || !productEditable}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.weightIn || ""}

                                                                                className={`form-control mb-3 ${validation.touched.weightIn && validation.errors.weightIn ? 'is-invalid' : ''}`}>
                                                                            <option value={""}>Elige</option>
                                                                            <option value={"kg"}>Kg</option>
                                                                            <option value={"g"}>Gramos</option>
                                                                            <option value={"lb"}>Lb</option>
                                                                            <option value={"oz"}>Oz</option>                                                                        </select>
                                                                        {validation.touched.weightIn && validation.errors.weightIn ? (
                                                                            <FormFeedback
                                                                                type="invalid">{validation.errors.weightIn}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            
                                                        </Col>
                                                        <Col sm="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="productdesc">
                                                                    Descripción del producto *
                                                                </Label>
                                                                <Input type={"textarea"}
                                                                       className="form-control mb-3"
                                                                       id="description" name={"description"}
                                                                       rows="5"  disabled={product?.rooteable || !productEditable}
                                                                       placeholder="Agrega la descripción del producto: medidas, material y cualquier detalle que sea importante"
                                                                       onChange={validation.handleChange}
                                                                       onBlur={validation.handleBlur}
                                                                       value={validation.values.description || ""}
                                                                       invalid={
                                                                           validation.touched.description && validation.errors.description ? true : false
                                                                       }
                                                                />
                                                                {validation.touched.description && validation.errors.description ? (
                                                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <RenderByCond cond={product?.rooteable} child={
                                                                <div className="mb-3">
                                                                <Label htmlFor="productdesc">
                                                                    Tipo de Producto *
                                                                </Label>
                                                                <Input type={"select"}
                                                                       className="form-control mb-3"
                                                                       id="typeProduct" name={"typeProduct"} 
                                                                       onChange={validation.handleChange} disabled={!productEditable}
                                                                       onBlur={validation.handleBlur}
                                                                       value={validation.values.typeProduct || ""}
                                                                       invalid={
                                                                           validation.touched.typeProduct && validation.errors.typeProduct ? true : false
                                                                       }
                                                                >
                                                                    <option value={""}>Slecciona...</option>
                                                                    {
                                                                        typesProduct?.map((type,key)=>{
                                                                            return (<option value={type.value} key={key}>{type.value}</option>)
                                                                        })
                                                                    }
                                                                    </Input>
                                                                {validation.touched.typeProduct && validation.errors.typeProduct ? (
                                                                    <FormFeedback type="invalid">{validation.errors.typeProduct}</FormFeedback>
                                                                ) : null}
                                                                </div>}/>
                                                        
                                                        </Col>
                                        
                                                        {product?.rooteable &&
                                                         <Col lg={12} md={12} sm={12} xs={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="productdesc">
                                                                    Descripción del producto para Shopify *
                                                                </Label>
                                                                
                                                                <CKEditor
                                                                        editor={ClassicEditor} 
                                                                        data={validation?.values?.bodyHtml}
                                                                        disabled={!productEditable}
                                                                        onChange={(event, editor) => {
                                                                            handleSetProductBodyHtml(editor?.getData());
                                                                        }}
                                                                />
                                                                
                                                                {validation.touched.bodyHtml && validation.errors.bodyHtml ? (
                                                                    <FormFeedback type="invalid">{validation.errors.bodyHtml}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>}
                                                        <Col sm={4} lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="length">
                                                                    Largo del producto (en cm) *
                                                                </Label>
                                                                <Input type={"number"}
                                                                       className="form-control mb-3"
                                                                       id="length" name={"length"}
                                                                       rows="5"  disabled={product?.rooteable || !productEditable}
                                                                       placeholder=""
                                                                       onChange={validation.handleChange}
                                                                       onBlur={validation.handleBlur}
                                                                       value={validation.values.length || ""}
                                                                       invalid={
                                                                           validation.touched.length && validation.errors.length ? true : false
                                                                       }
                                                                />
                                                                {validation.touched.length && validation.errors.length ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.length}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        
                                                        <Col sm={4} lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="width">
                                                                    Ancho del producto (en cm) *
                                                                </Label>
                                                                <Input type={"number"}
                                                                       className="form-control mb-3"
                                                                       id="width" name={"width"}
                                                                       rows="5"  disabled={product?.rooteable || !productEditable}
                                                                       placeholder=""
                                                                       onChange={validation.handleChange}
                                                                       onBlur={validation.handleBlur}
                                                                       value={validation.values.width || ""}
                                                                       invalid={
                                                                           validation.touched.width && validation.errors.width ? true : false
                                                                       }
                                                                />
                                                                {validation.touched.width && validation.errors.width ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.width}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={4} lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="depth">
                                                                    Profundidad del producto (en cm)
                                                                </Label>
                                                                <Input type={"number"}
                                                                       className="form-control mb-3"
                                                                       id="depth" name={"depth"}
                                                                       rows="5"  disabled={product?.rooteable || !productEditable}
                                                                       placeholder=""
                                                                       onChange={validation.handleChange}
                                                                       onBlur={validation.handleBlur}
                                                                       value={validation.values.depth || ""}
                                                                       invalid={
                                                                           validation.touched.depth && validation.errors.depth ? true : false
                                                                       }
                                                                />
                                                                {validation.touched.depth && validation.errors.depth ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.depth}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={4} lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="depth">
                                                                    Capacidad del producto (en mililitros)
                                                                </Label>
                                                                <Input type={"number"}
                                                                       className="form-control mb-3"
                                                                       id="capacity" name={"capacity"}
                                                                       disabled={product?.rooteable || !productEditable}
                                                                       placeholder=""
                                                                       onChange={validation.handleChange}
                                                                       onBlur={validation.handleBlur}
                                                                       value={validation.values.capacity || ""}
                                                                       invalid={
                                                                           validation.touched.capacity && validation.errors.capacity ? true : false
                                                                       }
                                                                />
                                                                {validation.touched.capacity && validation.errors.capacity ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.capacity}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Card>
                                                            <CardBody>
                                                            <Col sm={6} lg={6} md={6}>
                                                                <button type="button" className="btn btn-info" onClick={()=>{
                                                                    setProductImage(true);
                                                                    toggleImageGalleryModal()
                                                                }}>Agregar imágenes para producto </button>
                                                            </Col>

                                                            <label className="mt-4">Imagenes para el producto</label>
                                                            <div className="container-images row">
                                                                {
                                                                    images?.map((img, key)=>{return(<Col key={key} xs={3}  lg={3} md={3} sm={3}>
                                                                        <img className="mb-4" src={img?.url_public}/>
                                                                        {(product?.rooteable || !productEditable) ?? <Button onClick={(key)=>handleRemoveImagesToProduct(key)} 
                                                                        className="btn btn-danger btn-sm" type="button"><i className="fas fa-times"></i></Button>}
                                                                        </Col>)})
                                                                }
                                                            </div>
                                                            </CardBody>
                                                        </Card>
                                                        <Col sm={12} lg={12} md={12}>
                                                            <div className="mb-3 mt-2">
                                                                <Label htmlFor="productVariantInput">¿Tu producto tiene
                                                                    Variantes? </Label>
                                                                <Label htmlFor="productVariantInput"
                                                                       className={"p-3 pt-3"}> Elige "SI" si tu producto
                                                                    tiene diferentes presentaciones, a continuación
                                                                    elige entre talla o tamaño, color o estilo</Label>
                                                                <div>
                                                                    <Switch name={"productVariantInput"}
                                                                            uncheckedIcon={<Offsymbol/>}
                                                                            checkedIcon={<OnSymbol/>}
                                                                            className="me-1 mb-sm-8 mb-2 ml-5"
                                                                            onColor="#626ed4"
                                                                            onChange={() => {
                                                                                handleProductVariant();
                                                                            }}
                                                                            checked={productVariant}
                                                                            onBlur={validation.handleBlur} disabled={product?.shopify_id}
                                                                            value={validation.values.productVariantInput || false}
                                                                            invalid={
                                                                                validation.touched.productVariantInput && validation.errors.productVariantInput ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.price && validation.errors.productVariantInput ? (
                                                                        <FormFeedback type="invalid">{validation.errors.productVariantInput}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {
                                                            productVariant ?
                                                                <div className="row">
                                                                    <Col sm={12} lg={4} md={4}>
                                                                        <div className="mb-3 mt-3">
                                                                            <div
                                                                                className="form-check form-check-danger">
                                                                                <input
                                                                                    type="checkbox"
                                                                                className="form-check-input"
                                                                                id="sizeCheck" disabled={product?.shopify_id}
                                                                                checked={addSizeCheck}
                                                                                onClick={() => {
                                                                                    let addSizeCheckL = !addSizeCheck
                                                                                    dispatch(onProductOptionCheck({variant:"size",valuesLength: addSizeCheckL?1:0}))
                                                                                }}

                                                                            />
                                                                            <label
                                                                                className="form-check-label  font-size-14"
                                                                                htmlFor="sizeCheck"
                                                                            >
                                                                                Agregar Tamaños o Tallas
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        addSizeCheck &&
                                                                        <div className="mb-3">
                                                                        <Label htmlFor="price">Tamaño o Talla: </Label>
                                                                        {
                                                                            (sizeList || []).map((item, key) => (
                                                                                <InputVariantValues productIDSaved={product?.shopify_id} rooteable={product?.rooteable} productEditable={productEditable} key={key} lists={sizeList} 
                                                                                handleChangeVariantValue={handleChangeVariantValue} 
                                                                                id={"size"} item={item} iterator={key} name={"size"} onAddRowVariantProduct={handleAddRowVariantProduct} 
                                                                                onRemoveRowVariantProduct={onRemoveRowVariantProduct} />
                                                                                
                                                                            ))
                                                                        }
                                                                    </div>}
                                                                </Col>
                                                                <Col sm={12} lg={4} md={4}>
                                                                    <div  className="mb-3 mt-3">
                                                                        <div className="form-check form-check-danger">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="colorCheck" disabled={product?.shopify_id}
                                                                                checked={addColorCheck}
                                                                                onClick={() => {
                                                                                    let addColorCheckL = !addColorCheck
                                                                                    dispatch(onProductOptionCheck({variant:"color",valuesLength: addColorCheckL?1:0}))
                                                                                }}
                                                                            />
                                                                            <label
                                                                                className="form-check-label  font-size-14"
                                                                                htmlFor="colorCheck"
                                                                            >
                                                                                Agregar Colores
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        addColorCheck &&
                                                                        <div className="mb-3">
                                                                        <Label htmlFor="price">Color</Label>
                                                                        {
                                                                            (colorList || []).map((item, key) => (
                                                                                <InputVariantValues productIDSaved={product?.shopify_id} rooteable={product?.rooteable} productEditable={productEditable} key={key} lists={colorList} handleChangeVariantValue={handleChangeVariantValue} 
                                                                                id={"color"} item={item} iterator={key} name={"color"} onAddRowVariantProduct={handleAddRowVariantProduct} 
                                                                                onRemoveRowVariantProduct={onRemoveRowVariantProduct} />
                                                                            ))
                                                                        }
                                                                    </div>}
                                                                </Col>
                                                                <Col sm={12} lg={4} md={4}>
                                                                    <div  className="mb-3 mt-3">
                                                                        <div className="form-check form-check-danger">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="styleCheck" disabled={product?.shopify_id}
                                                                                checked={addStyleCheck}
                                                                                onClick={() => {
                                                                                    let addStyleCheckL = !addStyleCheck
                                                                                    dispatch(onProductOptionCheck({variant:"style",valuesLength: addStyleCheckL?1:0}))
                                                                                }}
                                                                            />
                                                                            <label
                                                                                className="form-check-label font-size-14"
                                                                                htmlFor="styleCheck"
                                                                            >
                                                                                Agregar Estilos
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        addStyleCheck &&
                                                                        <div className="mb-3">
                                                                        <Label htmlFor="price">Estilo</Label>
                                                                        {
                                                                            (styleList || []).map((item, key) => (

                                                                                <InputVariantValues productIDSaved={product?.shopify_id} rooteable={product?.rooteable} productEditable={productEditable} key={key} lists={styleList} handleChangeVariantValue={handleChangeVariantValue} 
                                                                                id={"style"} item={item} iterator={key} name={"style"} onAddRowVariantProduct={handleAddRowVariantProduct} 
                                                                                onRemoveRowVariantProduct={onRemoveRowVariantProduct} />

                                                                                
                                                                            ))
                                                                        }
                                                                    </div>}
                                                                    </Col>
                                                                </div>:<>
                                                                    <Col sm={12} lg={6} md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="price">Precio por artículo (Pesos Mexicanos)</Label>
                                                                            <Input
                                                                                id="priceUnit"
                                                                                name="priceUnit"
                                                                                type="number"
                                                                                className="form-control"
                                                                                placeholder="0.00"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.priceUnit || ""}
                                                                                invalid={
                                                                                    validation.touched.priceUnit && validation.errors.priceUnit ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.priceUnit && validation.errors.priceUnit ? (
                                                                                <FormFeedback type="invalid">{validation.errors.priceUnit}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={12} lg={6} md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="price">Cantidad disponible</Label>
                                                                            <Input
                                                                                id="quantity"
                                                                                name="quantity"
                                                                                type="number"
                                                                                className="form-control"
                                                                                placeholder="1"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.quantity || ""}
                                                                                invalid={
                                                                                    validation.touched.quantity && validation.errors.quantity ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.quantity && validation.errors.quantity ? (
                                                                                <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col></>
                                                        }
                                                        {
                                                            productVariant?
                                                                <>
                                                                <div className="border-top">
                                                                    
                                                                <label className="h5 mb-1 mt-4">Precio para todas las
                                                                        variantes {product?.rooteable?"(en Dolares)":"(en Pesos Mexicanos)"} </label>
                                                                    <div>
                                                                        <Input className="form-control me-auto"
                                                                                       type="number" onChange={(e)=>setPriceMain(e.target.value)}
                                                                                       placeholder="" 
                                                                               value={priceMain} disabled={!productEditable}
                                                                                       aria-label=""/>

                                                                    </div>
                                                                    <div className="hstack gap-2 mt-2">
                                                                        <button type="button" onClick={handleResetPriceAll}
                                                                                className="btn btn-secondary">Resetear
                                                                                    precio
                                                                        </button>
                                                                        <button type="button" onClick={handleApplyPriceAll}
                                                                                className="btn btn-warning">Aplicar
                                                                            a todo
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            <div  className={"col-md-12 col-lg-12 col-md-12"}>
                                                                <h5 className={"mb-1 mt-4"}>Variantes disponibles</h5>
                                                            </div>
                                                            <ProductVariantImages productEditable={productEditable} rooteable={product?.rooteable} toggleImageGalleryModal={toggleImageGalleryModal} 
                                                            handleAddPriceVariant={handleAddPriceVariant} variantsBuild={variantsBuild}/>
                                                        </>:""}
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter className="text-end">
                                {
                                    (product?.rooteable  && !productEditable) ?
                                    <><button  onClick={(e)=>{
                                        e.preventDefault();
                                        setProductStatus("draft");
                                        validation.handleSubmit();
                                        return false;
                                    }} className=" btn btn-warning m-4">Enviar en Shopify como BORRADOR</button>
                                    <button onClick={(e)=>{
                                        e.preventDefault();
                                        setProductStatus("active");
                                        validation.handleSubmit();
                                        return false;
                                    }} className=" btn btn-danger m-4">Enviar a Shopify como ACTIVO</button></>:""
                                }
                        
                                {
                                    productEditable ?
                                    <Button
                                    className={ "btn btn-success btn-lg" }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setProductStatus(null);
                                        validation.handleSubmit();
                                        return false;
                                    }} >{ productIDSaved ? "Guardar Cambios":"Guardar" }
                                </Button>:<Button
                                    className={ "btn btn-default " }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePruductEditable();
                                        return false;
                                    }} >Editar producto</Button>
                                }         
                        </CardFooter>
                    </Card>
                </Container>
            </div>
            <Modal size={"lg"} isOpen={!acceptTermsShow} >
                <ModalHeader  tag="h4">
                    Términos de uso
                </ModalHeader>
                <ModalBody>
                    <TermsArtisan/>
                </ModalBody>
                <ModalFooter>
                    <button onClick={handleAcceptedTerms} className="btn btn-success" >Acepto las Condiciones</button>
                </ModalFooter>
            </Modal>

            <Modal size={"lg"} isOpen={imageGalleryShow} toggle={toggleImageGalleryModal}>
                <ModalHeader toggle={toggleImageGalleryModal} tag="h4">
                </ModalHeader>
                <ModalBody>
                    <ImageGallery />
                </ModalBody>
                <ModalFooter>
                    <button onClick={toggleImageGalleryModal} className="btn btn-success">Cerrar</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

ProductEdit.propTypes = {
    product: PropTypes.object,
    match: PropTypes.any,
};

export default withRouter(ProductEdit);
