import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
    SAVE_PRODUCT_SUCCESS,
    SAVE_PRODUCT_FAIL,
    PRODUCT_ADD_TAB_ACTIVE_UPDATE,
    GET_PRODUCT_DETAIL_SUCCESS,
    SET_PRODUCT_CLEAN,
    SET_PRODUCT_VARIANT_FLAG,
    SET_PRODUCT_SIZE_LIST,
    SET_PRODUCT_COLOR_LIST,
    SET_PRODUCT_STYLE_LIST,
    SET_PRODUCT_SIZE_CHECK,
    SET_PRODUCT_COLOR_CHECK,SET_VARIANT_EDITBLE,
    SET_PRODUCT_STYLE_CHECK, SET_PRODUCT_OPTIONS, SET_VARIANTS_BUILD, SET_VARIANTS,
} from "./actions"

const INIT_STATE = {
    productList: [],
    product:{},
    productVariant:false,
    productSaveSuccess:false,
    productListSuccess:false,
    activeTab:1,
    productIDSaved:null,
    sizeList:[],
    colorList:[],
    styleList:[],
    addSizeCheck:false,
    addColorCheck:false,
    addStyleCheck:false,
    options:[{"name":"Size","valuesOld":[],"values":[],"shopify_values":[],guelaguetza_id:null},
        {"name":"Color","valuesOld":[],"values":[],"shopify_values":[],guelaguetza_id:null},
        {"name":"Style","valuesOld":[],"values":[],"shopify_values":[],guelaguetza_id:null}],
    variantsBuild:[],
    variants:[],
    variantEditable:null
  }
          
  const Products = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_PRODUCTS_SUCCESS:
        return {
          ...state,
          productList: action.payload.data,
            productListSuccess:action.payload.success
        }
      case GET_PRODUCTS_FAIL:
        return {
            ...state,
            error: action.payload,
        }
      case SAVE_PRODUCT_SUCCESS:
          return {
            ...state,
              productSaveSuccess:action.payload.success,
              productIDSaved:action.payload.data.id
        }
      case PRODUCT_ADD_TAB_ACTIVE_UPDATE:
          return {
            ...state,
              activeTab:action.payload
        }
      case SAVE_PRODUCT_FAIL:
        return {
            ...state,
            error: action.payload,
            productSaveSuccess:action.payload.success,
        }
        case GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                product: action.payload.data,
                productIDSaved:action.payload.data.id
            };
        case SET_PRODUCT_CLEAN:
            return {
                ...state,
                product: action.payload,
                productIDSaved:null,
                sizeList:[],
                colorList:[],
                styleList:[],
                productVariant:false,
                addSizeCheck:false,
                addColorCheck:false,
                addStyleCheck:false,
                options:[{"name":"Size","valuesOld":[],"values":[],"shopify_values":[],guelaguetza_id:null},
                {"name":"Color","valuesOld":[],"values":[],"shopify_values":[],guelaguetza_id:null},
                {"name":"Style","valuesOld":[],"values":[],"shopify_values":[],guelaguetza_id:null}],
                variantsBuild:[],
                variants:[],
                activeTab: 1

            };
        case SET_PRODUCT_VARIANT_FLAG:
            return {
                ...state,
                productVariant: action.payload,
            };
        case SET_PRODUCT_SIZE_LIST:
            console.info("SET_PRODUCT_SIZE_LIST",action.payload)
            return {
                ...state,
                sizeList: action.payload.map(item => ({
                        ...item,
                        serverId: item.serverId || item.id // Mantener el ID del servidor
                      }))
            };
        case SET_PRODUCT_COLOR_LIST:
            return {
                ...state,
                colorList: action.payload.map(item => ({
                    ...item,
                    serverId: item.serverId || item.id // Mantener el ID del servidor
                  }))
            };
        case SET_PRODUCT_STYLE_LIST:
            return {
                ...state,
                styleList: action.payload.map(item => ({
                        ...item,
                        serverId: item.serverId || item.id // Mantener el ID del servidor
                      }))
            };
        case SET_PRODUCT_SIZE_CHECK:
            return {
                ...state,
                addSizeCheck: action.payload,
            };
        case SET_PRODUCT_COLOR_CHECK:
            return {
                ...state,
                addColorCheck: action.payload,
            };
        case SET_PRODUCT_STYLE_CHECK:
            return {
                ...state,
                addStyleCheck: action.payload,
            };
        case SET_PRODUCT_OPTIONS:
            return {
                ...state,
                options: action.payload,
            };
        case SET_VARIANTS_BUILD:
            return {
                ...state,
                variantsBuild: action.payload,
            };
        case SET_VARIANTS:
            return {
                ...state,
                variants: action.payload,
            };
        case SET_VARIANT_EDITBLE: 
            console.info(action.payload)
            return {
                ...state,
                variantEditable: action.payload
            }
      default:
        return state
      }
  }
          
  export default Products